import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import "lazysizes"

import { Link, graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"
import LangContext from "../contexts/LangContext"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "@material-ui/core"
import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import VideoEmbed from "../components/VideoEmbed"

const useStyles = makeStyles(theme => ({
  image: {
    width: "100%",
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
  },
}))

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {children}
  </Typography>
)

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const PatientsInfoPage = ({
  data: { patientsInfoPage, guidesPage },
  pageContext: { locale, breadcrumbs },
  location,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const schema = patientsInfoPage.data.schema_json.text || ""
  const ogp = patientsInfoPage.data.ogp_tags || ""
  const meta = {
    title: patientsInfoPage.data.meta_title,
    description: patientsInfoPage.data.meta_description,
    keywords: patientsInfoPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <SectionTitle component="h1">
                {patientsInfoPage.data.title.text}
              </SectionTitle>
            </Grid>

            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label={i18n.translations.video} {...a11yProps(0)} />
                <Tab label={i18n.translations.patientGuide} {...a11yProps(1)} />
              </Tabs>
            </Grid>
            <br />
            <br />

            <Grid item xs={12}>
              <TabPanel value={value} index={0}>
                <Grid container spacing={4} direction="column">
                  {patientsInfoPage.data.videos.map(video => (
                    <Grid item key={shortid.generate()}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={5}>
                          <VideoEmbed video={video.video} />
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <Typography variant="h2" component="h2">
                            {video.info_title.text}
                          </Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: video.info_content.html,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Divider variant="middle" />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid
                  container
                  direction="column"
                  spacing={4}
                  className={classes.cardGrid}
                  justifyContent="flex-start"
                >
                  {patientsInfoPage.data.guides.map(guide => (
                    <Grid item key={shortid.generate()}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={3}>
                          <GatsbyImage
                            className={classes.image}
                            image={guide.thumbnail.gatsbyImageData}
                            alt={guide.guide_title.text}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Typography variant="h2" component="h2">
                            {guide.guide_title.text}
                          </Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: guide.guide_content.html,
                            }}
                          />
                          <div className={classes.buttons}>
                            {guide?.file?.url && (
                              <Button
                                href={guide.file.url}
                                variant="outlined"
                                component="a"
                                target="_blank"
                              >
                                {i18n.translations.download}
                              </Button>
                            )}
                            {guide?.guide_page?.document && (
                              <Button
                                variant="outlined"
                                component={Link}
                                to={`${localizedSlugPrefix(
                                  guide.guide_page.document
                                )}/${guidesPage.uid}/${guide.guide_page.document.uid
                                  }/`}
                              >
                                {i18n.translations.viewGuide}
                              </Button>
                            )}

                            {guide?.external_url?.url && (
                              <Button
                                href={guide.external_url.url}
                                variant="outlined"
                                className={classes.button}
                                component="a"
                                target={guide?.external_url?.target}
                              >
                                {i18n.translations.open}
                              </Button>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <br />
                      <Divider variant="middle" />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default PatientsInfoPage

export const query = graphql`
  query PatientsInfoPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    guidesPage: prismicGuidesPage(lang: { eq: $locale }) {
      uid
    }

    patientsInfoPage: prismicPatientsInfoPage(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      data {
        title {
          text
        }
        videos {
          video {
            embed_url
            title
            thumbnail_url
          }
          info_title {
            text
          }
          info_content {
            html
          }
        }
        guides {
          file {
            url
          }
          guide_page {
            document {
              ... on PrismicGuide {
                data {
                  title {
                    text
                  }
                }
                uid
                lang
              }
            }
          }
          external_url {
            url
            target
          }
          thumbnail {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          guide_title {
            text
          }
          guide_content {
            html
          }
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }
  }
`

PatientsInfoPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    guidesPage: PropTypes.object.isRequired,
    patientsInfoPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
